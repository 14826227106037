import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

export default class SentryManager
{
    constructor() {

        let sampleRate = 0.01;
        let ignoreErrors = [
            'undefined is not an object (evaluating \'this.element.config.onChange=[t]\')',
            'Non-Error promise rejection captured with value: Timeout (n)',
            'null is not an object (evaluating \'Object.prototype.hasOwnProperty.call(o,"telephone")\')',
            'undefined is not an object (evaluating \'this.element.config.onChange=[e]\')',
            'Failed to execute \'attachShadow\' on \'Element\': Shadow root cannot be created on a host which already hosts a shadow tree.',
            't.animate is not a function. (In \'t.animate([{maxHeight:t.clientHeight+"px"},{maxHeight:i}],{duration:parseInt(o),easing:"ease"})\', \'t.animate\' is undefined)',
            'Can\'t find variable: myApp',
            'Non-Error promise rejection captured with value: Timeout (u)',
            'Failed to read the \'cssRules\' property from \'CSSStyleSheet\': Cannot access rules',
            'Non-Error promise rejection captured with value: Timeout',
            'Non-Error promise rejection captured with value: XhrError',
            'Unexpected token ?',
            'undefined is not an object (evaluating \'i(e).offset().top\')',
            'Non-Error promise rejection captured with value: Object Not Found Matching Id:4',
            'TypeError: undefined is not an object (evaluating \'__gCrWeb.instantSearch.clearHighlight\')',
            'TypeError: undefined is not an object (evaluating \'__gCrWeb.instantSearch.setIOSParameters\')',
            'The Google Maps JavaScript API could not load.',
            '$ is not defined',
            'AbortError: The operation was aborted.',
            'Can\'t find variable: $',
            'myApp is not defined',
            'customElements is not defined',
            /^.*e.animate is not a function.*$/i,
            /^.*window.cobrowseIOgetRedactedElementsBounds is not a function.*$/i,
            /^.*Loading chunk*$/i
        ];

        if (navigator.userAgent.match(/Google-HotelAdsVerifier/i)) {
            sampleRate = 1;
            ignoreErrors = [];
        }

        if (document.location.pathname == '/') {
            sampleRate = 1;
        }

            Sentry.init({
            dsn: sentryConfig.sentryDsn,
            environment: "production_js",
            integrations: [new Integrations.BrowserTracing()],
            sampleRate: sampleRate,
            tracesSampleRate: sampleRate,
            ignoreErrors: ignoreErrors,
        });
    }
}
